import { rgba } from "polished"
import styled from "styled-components"
import { colors } from "./styles"

const Button = styled.button`
  background: ${(props) => (props.ibb ? colors.ibb : colors.yellow)};
  color: #fff;
  display: inline-block;
  border-radius: 3px;
  text-transform: uppercase;
  transition: 0.2s ease;
  text-align: center;
  padding: ${(props) => (props.big ? "10px 15px" : "6px 12px")};
  font-weight: ${(props) => (props.big ? 600 : 400)};
  box-shadow: ${(props) =>
    props.big
      ? `${rgba("#000", 0.1)} 0px 20px 25px -5px,
      ${rgba("#000", 0.04)} 0px 10px 10px -5px`
      : "none"};

  &:hover {
    box-shadow: 0 0 0 3px
      ${(props) =>
        props.ibb ? rgba(colors.ibbLight, 0.5) : rgba(colors.yellowLight, 0.5)};
    background: ${(props) =>
      props.ibb ? colors.ibbLight : colors.yellowLight};
    color: #fff;
  }
`

export default Button
