import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { rgba } from "polished"
import React from "react"
import Helmet from "react-helmet"
import styled, { createGlobalStyle } from "styled-components"
import brandonBlackWoff from "../fonts/brandon-black.woff"
import brandonBlackWoff2 from "../fonts/brandon-black.woff2"
import brandonBoldWoff from "../fonts/brandon-bold.woff"
import brandonBoldWoff2 from "../fonts/brandon-bold.woff2"
import brandonRegularWoff from "../fonts/brandon-regular.woff"
import brandonRegularWoff2 from "../fonts/brandon-regular.woff2"
import ibbLogoSquare from "../images/ibb-logo-square.svg"
import logoRound from "../images/logo-round.svg"
import Button from "./Button"
import { colors } from "./styles"

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "brandon";
    font-weight: 400;
    src: url(${brandonRegularWoff2}) format("woff2"),
      url(${brandonRegularWoff}) format("woff");
  }

  @font-face {
    font-family: "brandon";
    font-weight: 600;
    src: url(${brandonBoldWoff2}) format("woff2"),
      url(${brandonBoldWoff}) format("woff");
  }

  @font-face {
    font-family: "brandon";
    font-weight: 900;
    src: url(${brandonBlackWoff2}) format("woff2"),
      url(${brandonBlackWoff}) format("woff");
  }

  * {
    box-sizing: border-box;
  }

  body {
    padding: 0;
    margin: 0;
    min-height: 100vh;
    font-family: Brandon, system-ui, -apple-system, segoe ui, Roboto, Helvetica,
      Arial, sans-serif, apple color emoji, segoe ui emoji;
    font-size: 18px;
    line-height: 1.5;
    color: ${colors.greyDark};
  }

  a {
    color: ${colors.yellow};
    text-decoration: none;
    transition: 0.2s ease;

    &:hover {
      color: ${colors.yellowLight};
    }
  }

  section,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    letter-spacing: 0.03rem;
    text-transform: uppercase;
    font-weight: 900;
    line-height: 1.2;

    .extra {
      display: block;
      font-size: 0.7em;
      color: ${colors.yellow};
    }
  }

  h1 {
    text-align: center;
    font-size: 2em;
    margin: 2em auto;
  }

  h2 {
    margin-top: 2em;
  }

  ul li {
    margin: 0.5rem 0;
  }

  section {
    max-width: 1500px;
    margin: 25px auto;
    padding: 0 25px;
  }

  article {
    max-width: 900px;
    margin: 0 auto;
  }

  hr {
    border: 0;
    border-top: 1px solid ${colors.greyLight};
  }

  .footnotes-sep {
    margin-top: 50px;
  }

  .footnotes {
    color: ${colors.grey};
    font-size: 0.9em;
  }

  .footnote-ref {
    font-size: 0.6em;
  }

  input[type="text"],
  input[type="email"] {
    appearance: none;
    border: 1px solid ${colors.greyLight};
    border-radius: 3px;
    padding: 3px 10px;
    font: inherit;
  }

  button {
    border: 0;
    cursor: pointer;
    font: inherit;
    margin: 0;
  }
`

const headerBreakpoint = 1000

const Header = styled.header`
  background: ${colors.yellow};
  box-shadow: 0 0 0 7px ${rgba(colors.yellow, 0.3)},
    0 0 0 14px ${rgba(colors.yellow, 0.3)};
  color: #fff;
  padding: 25px;
  position: relative;
  z-index: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  > * {
    position: relative;
  }

  @media (min-width: ${headerBreakpoint}px) {
    flex-direction: ${(props) => (props.bigHeader ? "column" : "row")};
  }
`

const HeaderLeft = styled.div`
  @media (min-width: ${headerBreakpoint}px) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`

const HeaderIbbStyled = styled.a`
  display: block;
  background: ${colors.greyDark};
  padding: 6px 12px;
  margin-top: 25px;
  border-radius: 8px;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.7);

  &:hover {
    box-shadow: 0 0 0 3px #fff;
  }

  @media (min-width: ${headerBreakpoint}px) {
    margin-top: 0;

    position: ${(props) => (props.bigHeader ? "absolute" : "relative")};
    top: ${(props) => (props.bigHeader ? "25px" : "auto")};
    right: ${(props) => (props.bigHeader ? "25px" : "auto")};
  }
`

function HeaderIbb({ bigHeader }) {
  return (
    <HeaderIbbStyled
      href="https://www.institutbiblique.be"
      bigHeader={bigHeader}
    >
      <StaticImage
        src="../images/ibb-logo.svg"
        height={30}
        alt="IBB"
        placeholder="blurred"
      />
    </HeaderIbbStyled>
  )
}

const HeaderNav = styled.nav`
  font-size: 1.3rem;
  font-weight: 600;
  margin-top: 25px;
  letter-spacing: 0.03rem;
  text-transform: uppercase;
  text-align: center;

  > * {
    display: block;
  }

  @media (min-width: ${headerBreakpoint}px) {
    margin-top: 20px;
    margin-top: ${(props) => (props.bigHeader ? "45px" : "20px")};
    line-height: 1;
    font-size: ${(props) => (props.bigHeader ? "1.3rem" : "1.15rem")};

    > * {
      display: inline-block;

      & + * {
        margin-left: 2.2rem;
      }
    }
  }

  a {
    color: #fff;

    &:hover {
      color: #fff;
      opacity: 0.7;
    }
  }
`

const Footer = styled.footer`
  background: ${colors.greyDark};
  color: #e5e7eb;
  padding: 55px 0;
  line-height: 1.3;

  p {
    margin: 0.5rem auto;
  }

  a {
    color: inherit;

    &:hover {
      color: ${colors.yellowLight};
    }
  }

  ul {
    list-style: none;
    padding: 0;
  }

  section {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 5rem;
    row-gap: 3rem;

    @media (max-width: 1000px) {
      grid-template-columns: 1fr 1fr;
    }
  }
`

const FooterTitle = styled.div`
  color: ${colors.grey};
  font-size: 0.9rem;
  text-transform: uppercase;
  padding-bottom: 4px;
  border-bottom: 1px solid ${colors.greyMidDark};
  margin-bottom: 1rem;
`

const FooterCheckbox = styled.label`
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;

  input {
    height: 1.8em;
    width: 1.8em;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border: 0;
    border-radius: 4px;
    outline: none;
    transition-duration: 0.3s;
    background-color: white;
    cursor: pointer;
    margin-right: 5px;

    &:checked {
      background-color: ${colors.yellow};

      &:after {
        content: "✓";
        display: block;
        text-align: center;
        color: white;
        position: absolute;
        line-height: 25px;
        font-size: 1.4em;
        top: 0;
        left: 0.4em;
      }
    }
  }
`

export default function Layout({ children, bigHeader }) {
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "grid",
        gridTemplateRows: "auto 1fr auto",
        gridTemplateColumns: "100%",
      }}
    >
      <GlobalStyle />

      <Helmet>
        <title>Bibliodok</title>
        <meta
          name="description"
          content="Des présentations de livres passés au crible des écritures proposées par l'Institut Biblique de Bruxelles."
        />
      </Helmet>

      <Header bigHeader={bigHeader}>
        <StaticImage
          style={{
            objectFit: "cover",
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            mixBlendMode: "overlay",
            opacity: 0.1,
          }}
          layout="fullWidth"
          alt=""
          src={"../images/header.jpg"}
        />

        <Link to="/">
          {bigHeader ? (
            <StaticImage
              src="../images/logo-big.svg"
              width={350}
              placeholder="blurred"
              alt="Bibliodok"
            />
          ) : (
            <StaticImage
              src="../images/logo.svg"
              width={350}
              placeholder="blurred"
              alt="Bibliodok"
            />
          )}
        </Link>

        {bigHeader && <HeaderIbb bigHeader />}
        <HeaderLeft>
          {!bigHeader && <HeaderIbb />}

          <HeaderNav bigHeader={bigHeader}>
            <Link to="/">Présentation</Link>
            <Link to="/nouveautes">Nouveautés</Link>
            <Link to="/recherche">Rechercher</Link>
          </HeaderNav>
        </HeaderLeft>
      </Header>

      <main>{children}</main>

      <Footer>
        <section>
          <div>
            <FooterTitle>L'Institut Biblique de Bruxelles</FooterTitle>
            <p>
              Nous voulons former, en faveur de la moisson de l'Europe
              francophone, des serviteurs de l'Évangile qui soient fidèles,
              compétents et consacrés – et cela pour la gloire de Dieu.
            </p>
          </div>
          <div>
            <FooterTitle>Nous contacter</FooterTitle>
            <p>Institut Biblique de Bruxelles ASBL</p>
            <p>
              <a
                href="https://goo.gl/maps/ndHVzEndG9BmWBSR7"
                target="ibbMap"
                rel="noopener noreferrer"
              >
                Rue du Moniteur, 7<br />
                1000 Bruxelles
              </a>
            </p>
            <p>
              <a href="tel:003222237956">+32 2 223 79 56</a>
            </p>
            <p>
              <a href="mailto:info@institutbiblique.be">
                info@institutbiblique.be
              </a>
            </p>
          </div>
          <div>
            <FooterTitle>Restez informés</FooterTitle>
            <form
              action="https://institutbiblique.us7.list-manage.com/subscribe/post?u=5acaafa0be1ff092aa2fc0e0a&amp;id=ddfeb97a0a"
              method="POST"
              className="lg:flex lg:space-x-10 space-y-4 lg:space-y-0 text-center justify-center items-center"
            >
              <div className="space-x-3 text-white">
                <FooterCheckbox>
                  <input
                    type="checkbox"
                    defaultChecked={true}
                    name="gdpr[3]"
                    value="Y"
                  />
                  <span>Mini-méditations du mercredi</span>
                </FooterCheckbox>
                <FooterCheckbox>
                  <input
                    type="checkbox"
                    defaultChecked={true}
                    name="gdpr[7]"
                    value="Y"
                  />
                  <span>Nouvelles</span>
                </FooterCheckbox>
                <FooterCheckbox style={{ display: "none" }}>
                  <input
                    type="checkbox"
                    defaultChecked={false}
                    name="gdpr[11]"
                    value="Y"
                  />
                  <span>Événements</span>
                </FooterCheckbox>
                <FooterCheckbox>
                  <input
                    type="checkbox"
                    defaultChecked={true}
                    name="gdpr[57390]"
                    value="Y"
                  />
                  <span>Recensions</span>
                </FooterCheckbox>
              </div>
              <div style={{ marginTop: 10 }}>
                <input
                  type="email"
                  required
                  name="EMAIL"
                  placeholder="Adresse e-mail"
                  style={{
                    display: "block",
                    width: "100%",
                  }}
                />
              </div>
              <div style={{ marginTop: 10 }}>
                <input
                  type="text"
                  name="b_5acaafa0be1ff092aa2fc0e0a_ddfeb97a0a"
                  tabindex="-1"
                  style={{ display: "none" }}
                  aria-hidden="true"
                />
                <Button
                  type="submit"
                  style={{
                    width: "100%",
                  }}
                >
                  Je m'inscris gratuitement
                </Button>
              </div>
            </form>
          </div>
          <div style={{ textAlign: "center" }}>
            <p>
              <img width={75} alt="Bibliodok" src={logoRound} />
            </p>
            <p style={{ marginTop: 20 }}>
              <a href="https://www.institutbiblique.be/ressources">
                <img width={75} alt="IBB" src={ibbLogoSquare} />
              </a>
            </p>
          </div>
        </section>
      </Footer>
    </div>
  )
}
