export const colors = {
  grey: "#6b7280",
  greyLight: "#e7e9ea",
  greyMidDark: "#374151",
  greyDark: "#212121",
  yellow: "#d7ad2b",
  yellowLight: "#eec547",
  ibb: "#1db3b0",
  ibbLight: "#1fcdc9",
}
